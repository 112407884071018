import React from 'react';
import CtaBlock from '../../components/components-group-one/CtaBlock';
import Footer from '../../components/footer';
import HtmlHead from '../../components/html-head';
import Navbar from '../../components/navbar';
import { PageData } from '../../types';
import ResourceHero from '../../components/resources-components/resource-hero';
import CaseStudy from '../../components/resources-components/case-study';
import jimFischer from '../../assets/resources/trintech.png';
import trintech from '../../assets/resources/Trintech logo-1.png';
import Trintechpdf from '../../assets/resources/Trintech.pdf';
import { graphql } from 'gatsby';

const Trintech: React.FC<PageData> = resp => {
    const seoData = resp?.data?.allContentfulPageSeoData?.edges[0]?.node;

    const resourceData = {
        formName : 'trintech',
        companyLogo: trintech,
        title: `Boosting Efficiency 
                and Customer 
                Satisfaction:`,
        author: {
            image: jimFischer,
            name: '<span>Jim</span> Fischer',
            title: 'Vice President, Support and Dataflow Services',
            quote: `AptEdge isn’t just about aggregating 
                    data from different sources. Its ability 
                    to synthesize data into relevant, 
                    accurate, and well-structured answers 
                    significantly decreases the agent’s 
                    effort and time necessary to deliver a 
                    solution, which is of great value to us.`
        },
        stats: [
            {
                percentage: '14%',
                title: 'reduction in Resolution Time'
            }
        ],
        pdf: Trintechpdf,
        ctaTitle: 'Download PDF',
        count: [],
        countColor: '#E75500',
        isdownload: true
    };

    const caseStudy = [
        {
            title: 'About',
            desc: `Trintech, a powerhouse in cloud-based software, 
                   specializes in financial close and reconciliation 
                   automation services. Founded in 1996 and headquartered 
                   in Dallas, Texas, Trintech's solutions supercharge the 
                   efficiency, reliability, and strategic insights of financial 
                   operations. With a clientele that spans over 4,200 global 
                   companies, including many Fortune 100 firms, Trintech is 
                   the unsung hero behind the financial maneuvers of more 
                   than 300,000 finance professionals.`
        },
        {
            title: 'Solution',
            desc: `<span>Key AptEdge features</span> <br />`,
            lists: [{
                heading: '',
                items: [
                    `<span>AptEdge Ticket Insights:</span> Summarizes cases and 
                    agent actions to date. Its main benefit is 
                    providing a comprehensive view of each case.`,
                    `<span>AptEdge Answer AI:</span> Accelerates and enhances 
                    the accuracy of support responses. Embedded 
                    in the agents' workflow within Salesforce, it 
                    significantly improves efficiency`
                ]
            }],
        },
        {
            title: 'Challenge',
            desc: ``,
            lists: [
                {
                    heading: '<span>1. Siloed Information and Knowledge Management:</span>',
                    items: [
                        'Trintech\'s support teams grappled with fragmented information. Each team member, hyper-focused on one or two products, struggled to provide holistic support.',
                        'Example: Agents repeatedly queried process questions in Teams, underscoring the need for a centralized information hub.'
                    ]
                },
                {
                    heading: '<span>2. Customer Satisfaction and Retention:</span>',
                    items: [
                        'Delays in resolving support cases ignited customer frustration and eroded satisfaction.'
                    ]
                },
                {
                    heading: '<span>3. Internal Communication and Resource Efficiency:</span>',
                    items: [
                        'Inefficient internal communication muddied response times and perplexed support agents.',
                        'Scaling support to meet growing demands without ballooning the team size necessitated efficient tools.'
                    ]
                }
            ],
        },
        {
            title: 'Results',
            desc: ``,
            lists: [
                {
                    heading: '<span>Cost Savings:</span>',
                    items: [
                        '14% reduction in solution time, saving approximately 3,000 days of customer waiting time annually.',
                        'Capacity improvement equivalent to about 4 full-time agents per year.'
                    ]
                },
                {
                    heading: '<span>Customer Retention:</span>',
                    items: [
                        'Increased customer satisfaction scores (CSAT scores for cases managed using AptEdge rose to 9.8).',
                        'Enhanced agent productivity and positive team feedback.',
                        'Improved knowledge base and support case data quality.'
                    ]
                }
            ],
        }
    ];

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <Navbar />
            <ResourceHero data={resourceData} />
            <CaseStudy data={caseStudy} />
            <CtaBlock ctaTitle="Start Today!" />
            <Footer />
        </>
    );
};

export default Trintech;

export const query = graphql`
    query TrintechQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/case-study/trintech" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;
