import React from 'react';
import './case-study.css';

interface CaseStudyList {
    heading: string;
    items: string[];
}

interface CaseStudy {
    title: string;
    desc?: string;
    lists?: CaseStudyList[];
}

function CaseStudy({ data }: { data: CaseStudy[] }) {
    return (
        <div className="resource-case-study">
            <div className="case-study-container">
                <div className="case-study">
                    {data.map((item, index) => (
                        <div className="case-study-box" key={index}>
                            <p className="case-title">{item.title}</p>
                            <div className="case-desc-wrapper">
                                {item.desc && (
                                    <p
                                        className="case-desc"
                                        dangerouslySetInnerHTML={{ __html: item.desc }}
                                    ></p>
                                )}
                                {item.lists?.map((list, idx) => (
                                    <div key={idx}>
                                        <p
                                            className="case-lists-heading"
                                            dangerouslySetInnerHTML={{ __html: list.heading }}
                                        ></p>
                                        <ul>
                                            {list.items?.map((listItem, itemIdx) => (
                                                <li
                                                    className="case-lists"
                                                    dangerouslySetInnerHTML={{ __html: listItem }}
                                                    key={itemIdx}
                                                ></li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className="case-study">
                    {data.slice(2, 4).map((item, index) => (
                        <div className="case-study-box" key={index}>
                            <p className="case-title">{item.title}</p>
                            <div className="case-desc-wrapper">
                                {item.desc && (
                                    <p
                                        className="case-desc"
                                        dangerouslySetInnerHTML={{ __html: item.desc }}
                                    ></p>
                                )}
                                {item.lists?.map((list, idx) => (
                                    <div key={idx}>
                                        <p
                                            className="case-lists-heading"
                                            dangerouslySetInnerHTML={{ __html: list.heading }}
                                        ></p>
                                        <ul>
                                            {list.items?.map((listItem, itemIdx) => (
                                                <li
                                                    className="case-lists"
                                                    dangerouslySetInnerHTML={{ __html: listItem }}
                                                    key={itemIdx}
                                                ></li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div> */}
            </div>
        </div>
    );
}

export default CaseStudy;
