import React, { useRef, useState, useEffect } from 'react';
import './resource-hero.css';
import PdfDownloadForm from '../../../components/case-studies-download/index';

interface Author {
    image: string;
    name: string;
    title: string;
    quote: string;
}

interface Stat {
    percentage: string;
    title: string;
}

interface Count {
    num: string;
    title: string;
}

interface ResourceData {
    companyLogo: string;
    title: string;
    author: Author;
    stats: Stat[];
    pdf: string;
    ctaTitle: string;
    count: Count[];
    countColor: string;
    isDownload: boolean;
    formName?: string;
}

function ResourceHero({ data }: { data: ResourceData }) {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false);
    const formRef = useRef<HTMLDivElement>(null);

    // Clear the "downloaded" flag on page refresh
    useEffect(() => {
        localStorage.removeItem('downloaded');
    }, []);

    const handleDownloadClick = () => {
        if (emailSubmitted || localStorage.getItem('downloaded')) {
            // Direct download after email submission
            const link = document.createElement('a');
            link.href = data.pdf;
            link.download = data.pdf.split('/').pop() || 'ebook.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            setShowForm(true);
        }
    };

    const handleFormSubmit = (downloadUrl: string) => {
        setEmailSubmitted(true);
        localStorage.setItem('downloaded', 'true');
        // Trigger the download after email submission
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = downloadUrl.split('/').pop() || 'ebook.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setShowForm(false);
    };

    const handleOverlayClick = (e: React.MouseEvent) => {
        if (formRef.current && !formRef.current.contains(e.target as Node)) {
            setShowForm(false);
        }
    };

    const formName = `${data.formName}-pdf-download-form`
    return (
        <div className="resource-hero" onClick={handleOverlayClick}>
            <div className="resource-hero-top">
                <div className="resource-left">
                    <div className="resource-textbox">
                        <img src={data.companyLogo} alt="Company Logo" />
                        <h1 dangerouslySetInnerHTML={{ __html: data.title }}></h1>
                    </div>
                    <div className="resource-author">
                        <div className="resource-author-image">
                            <img src={data.author.image} alt="Author" />
                        </div>
                        <div className="author-txt-box">
                            <div className="author-title">
                                <p className="author-name" dangerouslySetInnerHTML={{ __html: data.author.name }}></p>
                                <p className="author-desc">{data.author.title}</p>
                            </div>
                            <div className="author-quote">{`"${data.author.quote}"`}</div>
                        </div>
                    </div>
                </div>
                <div className="resource-right">
                    <div className="resource-stats-section">
                        {data.stats.map((item, i) => (
                            <div className="resource-stat" key={i}>
                                <p className="stat-percentage">{item.percentage}</p>
                                <p className="stat-title">{item.title}</p>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={handleDownloadClick}
                        className="download-button"
                        dangerouslySetInnerHTML={{ __html: data.ctaTitle }}
                    ></button>
                </div>
            </div>
            <div className="resource-hero-bottom">
                {data.count.map((item, i) => (
                    <div className="resource-stats-two" key={i}>
                        <p className="stat-percentage" style={{ color: data.countColor }}>
                            {item.num}
                        </p>
                        <p className="stat-title">{item.title}</p>
                    </div>
                ))}
            </div>
            {/* ----------------- */}
            {/* netlify needs the html of a form to set Active forms which will be available uring the build
            but since we have conditionally rendered the form in modal it will only be available in client side js
            i.e after the user event triggered the client js loads the form in html which prevents the netily from 
            tracking the Active forms, so to fix it a duplicate form is created here with similar names and attributes 
            and is set hidden so user will not see it but during build netlify can access the html */}
            <form name={formName} method='post' data-netlify="true" hidden>
                <input
                    type="email"
                    name="email"
                    hidden
                />
                <input type='hidden' name='form-name' value={formName} />
            </form>
            {/* ----------------- */}
            {showForm && (
                <div className="overlay">
                    <div className="subscribe-container" ref={formRef}>
                        <PdfDownloadForm pdfUrl={data.pdf} onSubmit={handleFormSubmit} formName={formName} title={''} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ResourceHero;

// above code is working perfectly fine as requirement 1. After every refresh the form will apper again 2. In each case-studies the form will apper 3. It only take the business email only 
