// import React, { useState, useRef } from 'react';
// import './pdf-download-form.css';
// import validateBusinessEmail from '../../utils/validate-business-email';
// import AppButton from '../CustomComponents/app-button';
// import { sendEventToGa } from '../../utils';

// interface PdfDownloadFormProps {
//     onSubmit: (downloadUrl: string) => void;
//     flexCol?: boolean;
//     buttonVariant?: string;
//     buttonText?: string;
//     pdfUrl: string;
// }

// const PdfDownloadForm: React.FC<PdfDownloadFormProps> = ({ onSubmit, flexCol, buttonVariant, buttonText, pdfUrl }) => {
//     const [email, setEmail] = useState<string>('');
//     const [emailError, setEmailError] = useState<boolean>(false);
//     const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
//     const [submitted, setSubmitted] = useState<boolean>(false);
//     const formRef = useRef<HTMLFormElement>(null);

//     const handleSubmit = () => {
//         setSubmitted(true);
//         setEmailError(false);
//         if (!validateBusinessEmail(email)) {
//             return setEmailError(true);
//         }

//         fetch('/', {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//             body: new URLSearchParams({
//                 'form-name': 'pdf-download-form',
//                 'email': email
//             }).toString()
//         })
//             .then(() => {
//                 setEmailSuccess(true);
//                 sendEventToGa('form_submit', { form: 'pdf-download-form' });
//                 if (onSubmit) onSubmit(pdfUrl);
//             })
//             .catch(error => {
//                 console.error(error);
//                 setEmailError(true);
//             });
//         return true;
//     };

//     return (
//         <div className="ebook-form-wrapper" style={{ width: flexCol ? "100%" : "586px" }}>
//             {emailSuccess ? (
//                 <div className="ebook-form-success">
//                     <p className="text-md">Thanks! Your email has been recorded.</p>
//                 </div>
//             ) : (
//                 <>
//                     <form
//                         name="pdf-download-form"
//                         method="post"
//                         data-netlify="true"
//                         data-netlify-honeypot="bot-field"
//                         noValidate
//                         ref={formRef}
//                         onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
//                             e.preventDefault();
//                             handleSubmit();
//                         }}
//                         style={{ flexDirection: flexCol ? "column" : "row" }}
//                     >
//                         <div className="input-wrapper">
//                             <label id="ebook-download-email">Email</label>
//                             <input
//                                 type="email"
//                                 name="email"
//                                 aria-labelledby="ebook-download-email"
//                                 placeholder="Your email address"
//                                 className={emailError ? 'error' : ''}
//                                 style={{ height: '48px' }}
//                                 onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
//                                     setEmail(e.currentTarget.value);
//                                     if (submitted) {
//                                         setEmailError(!validateBusinessEmail(e.currentTarget.value));
//                                     }
//                                 }}
//                             />
//                             <p className={emailError ? 'input-error error' : 'input-error'}>
//                                 {submitted && emailError && 'Please enter a valid email address'}
//                             </p>
//                         </div>
//                         <input type="hidden" name="form-name" value="pdf-download-form" />
//                         <AppButton
//                             variant={buttonVariant ?? 'variant-teal'}
//                             text={buttonText || 'Subscribe'}
//                             hasArrow
//                             style={{ fontWeight: "400", lineHeight: "0", marginLeft: flexCol ? "0em" : "1em", maxWidth: "165px" }}
//                         />
//                     </form>
//                 </>
//             )}
//         </div>
//     );
// };

// export default PdfDownloadForm;


import React, { useState, useRef } from 'react';
import './pdf-download-form.css';
import validateBusinessEmail from '../../utils/validate-business-email';
import AppButton from '../CustomComponents/app-button';
import { sendEventToGa } from '../../utils';


interface PdfDownloadFormProps {
    onSubmit: (downloadUrl: string) => void;
    flexCol?: boolean;
    buttonVariant?: string;
    buttonText?: string;
    pdfUrl: string;
    title: string;
    formName: string;
}

const PdfDownloadForm: React.FC<PdfDownloadFormProps> = ({ onSubmit, flexCol, buttonVariant, buttonText, pdfUrl, title, formName }) => {
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const form_Name = formName;

    const handleSubmit = () => {
        setSubmitted(true);
        setEmailError(false);
        if (!validateBusinessEmail(email)) {
            return setEmailError(true);
        }

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                'form-name': form_Name,
                'email': email
            }).toString()
        })
            .then(() => {
                setEmailSuccess(true);
                sendEventToGa('form_submit', { form: form_Name });
                if (onSubmit) onSubmit(pdfUrl);
            })
            .catch(error => {
                console.error(error);
                setEmailError(true);
            });
        return true;
    };

    return (
        <div className="case-studies-form-wrapper">
            {/* style={{ width: flexCol ? "100%" : "586px" }} */}
            {emailSuccess ? (
                <div className="case-studies-form-success">
                    <p className="text-md">Thanks! Your email has been recorded.</p>
                </div>
            ) : (
                <>
                    <form
                        name={form_Name}
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        noValidate
                        ref={formRef}
                        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                        style={{ flexDirection: flexCol ? "column" : "row" }}
                    >
                        <div className="input-wrapper">
                            <label id="case-studies-download-email">Email</label>
                            <input
                                type="email"
                                name="email"
                                aria-labelledby={"case-studies-download-email"}
                                placeholder="Your email address"
                                className={emailError ? 'error' : ''}
                                style={{ height: '48px' }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setEmail(e.currentTarget.value);
                                    if (submitted) {
                                        setEmailError(!validateBusinessEmail(e.currentTarget.value));
                                    }
                                }}
                            />
                            <p className={emailError ? 'input-error error' : 'input-error'}>
                                {submitted && emailError && 'Please enter a valid email address'}
                            </p>
                        </div>
                        <input type="hidden" name="form-name" value={form_Name} />
                        <AppButton
                            variant={buttonVariant ?? 'variant-teal'}
                            text={buttonText || 'Download Case Study'}
                            // hasArrow
                            style={{ fontWeight: "400", lineHeight: "0", maxWidth: "100%" }}
                        // marginLeft: flexCol ? "0em" : "1em",
                        />
                    </form>
                </>
            )}
        </div>
    );
};

export default PdfDownloadForm;



